import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './InstaModal.scss';
import getYouTubeEmbedURL from '../../utils/constants'; // Adjust the path as necessary

function InstaModal({
  insta, onClose, onNext, onPrev, showInstaModal, isMobile,
}) {
  const dialogRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (showInstaModal) {
      dialogRef.current.showModal();
    } else {
      dialogRef.current.close();
    }
  }, [showInstaModal]);

  const calculateDimensions = () => {
    const aspectRatio = 315 / 560;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const maxWidth = isMobile ? windowWidth : windowWidth * 0.9;
    const maxHeight = isMobile ? windowHeight - 80 : windowHeight * 0.9;

    let width = maxWidth;
    let height = width / aspectRatio;

    if (height > maxHeight) {
      height = maxHeight;
      width = height * aspectRatio;
    }

    setDimensions({ width, height });
  };

  useEffect(() => {
    calculateDimensions();
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach(() => {
        calculateDimensions();
      });
    });

    resizeObserver.observe(document.getElementById('container'));

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleBackdropClick = (event) => {
    if (dialogRef.current && event.target === dialogRef.current) {
      onClose();
    }
  };

  return (
    <dialog className="ds-instaModal" ref={dialogRef} onClick={handleBackdropClick}>
      <div className="ds-instaModal__frame">
        <iframe
          title={insta.title}
          width={dimensions.width}
          height={dimensions.height}
          src={getYouTubeEmbedURL(insta.id)}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="ds-instaModal__embed"
        />
        <button
          type="button"
          onClick={onClose}
          className="ds-instaModal__close"
        />
        <button
          type="button"
          onClick={onPrev}
          className="ds-instaModal__prev"
          aria-label="Previous"
        />
        <button
          type="button"
          onClick={onNext}
          className="ds-instaModal__next"
          aria-label="Next"
        />
      </div>
    </dialog>
  );
}

InstaModal.propTypes = {
  insta: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  showInstaModal: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default InstaModal;
