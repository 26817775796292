import React from 'react';
import PropTypes from 'prop-types';
import { InstaPropTypes } from '../../utils/propTypes';
import './InstaCard.scss';

function InstaCard({ insta, onClick }) {
  function formatDuration(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  return (
    <div className="carousel-cell ds-instaWidgetCard" onClick={onClick}>
      <img src={insta.thumbnails.shorts} alt={insta.title} loading="lazy" className="ds-instaWidgetCard__thumbnail" />
      <div className="ds-instaWidgetCard__text">
        <b>{insta.title}</b>
        <span>{formatDuration(insta.duration)}</span>
      </div>
      <i className="videoPlayButton" />
    </div>
  );
}

InstaCard.propTypes = {
  insta: InstaPropTypes.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default InstaCard;
